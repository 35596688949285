.glassjar__swiper-elements {
  overflow: hidden;
  position: relative;
  width   : 100%;
  z-index : 1;
}

.glassjar__swiper-elements__swipe-over * {
  /* pointer-events: none; */
}

.glassjar__swiper-elements__swipe-over {
  box-sizing: border-box;
  min-width : 100%;
  width     : 100%;
  /* pointer-events: auto; */
  box-shadow: 0 1px 1px rgba(0,0,0,0.08), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.16), 0 8px 8px rgba(0,0,0,0.20);
}

.glassjar__swiper-elements__swipe-under {
  display       : flex;
  flex-direction: row-reverse;
  height        : 100%;
  position      : absolute;
  right         : 0;
  top           : 0;
  width         : 100%;
  z-index       : -1;
}
