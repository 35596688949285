            /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__landing {
  align-items    : center;
  background     : var(--color-bright);
  box-sizing     : border-box;
  display        : flex;
  flex-direction : column;
  height         : 100%;
  max-height: 100vh;
  justify-content: space-between;
  left           : 0;
  /* padding        : 1.5rem; */
  position       : absolute;
  top            : 0;
  width          : 100%;
  z-index        : 99999;
}

.glassjar__landing > div {
  align-items    : center;
  /* border-radius  : 1rem; */
  box-sizing     : border-box;
  display        : flex;
  flex           : 2;
  justify-content: center;
  /* max-height     : 90%; */
  max-width      : 100vw;
  overflow       : hidden;
  position       : relative;
  width          : 100%;
}

.glassjar__landing__img.glassjar__landing__img--hero {
  height         : 100%;
  object-fit     : cover;
  object-position: top center;
  width          : 100%;
}

.glassjar__landing__img.glassjar__landing__img--copyright {
  height: 0.5rem;
}

.glassjar__landing__img.glassjar__landing__img--logo {
  max-width: calc(100% - 6rem);
}

/* Form */

.glassjar__landing__form {
  bottom         : 0.5rem;
  box-sizing     : border-box;
  display        : flex;
  flex-direction : column;
  gap            : .5rem;
  justify-content: space-between;
  margin         : 0.5rem;
  padding: 1rem;
  max-width      : calc(100% - 2rem);
  position       : absolute;
  width          : 100%;
  border-radius: 2rem;
  background: var(--color-bright);
}

.glassjar__landing__form > div {
  bottom         : 0.5rem;
  box-sizing     : border-box;
  width          : 100%;  
}

.glassjar__landing-image-group {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}


.glassjar__landing h1 {
  font-weight: 500;
}

.glassjar__landing__form h1 {
  margin     : 0;
  text-align : right;
  /* text-shadow: 0px 4px 6px rgba(54, 110, 121, 0.2),
                0px 8px 12px rgba(54, 110, 121, 0.2); */
  width      : 100%; 
}
/* 
.glassjar__landing p {
  color     : var(--color-bright);
} */

.glassjar__landing__form p {
  margin    : 0;
  text-align: center;
}


/* Welcome Content */

.glassjar__welcome-content {
  backdrop-filter: blur(0px);
  background     : rgb(0 0 0 / 70%);
  box-sizing     : border-box;
  max-height     : 100%;
  min-height     : 100%;
  min-width      : 100%;
  opacity        : 0;
  overflow       : auto;
  pointer-events : none;
  position       : absolute;
  transition     : .5s ease;
  z-index        : 10;
}

.glassjar__welcome-content section {
  padding        : 1rem 3rem 3rem 3rem;
}

.glassjar__welcome-content.open {
  backdrop-filter: blur(4px);
  opacity        : 1;
  pointer-events : auto;
}

.glassjar__welcome-content h1 {
  color      : var(--color-bright);
  margin-top: .5rem;
}

button.glassjar__more-tab {
    align-items    : center;
    background     : var(--color-interaction);
    border         : none;
    border-radius  : 100rem 0 0 100rem;
    box-sizing     : border-box;
    display        : flex;
    font-size      : 1.6rem;
    justify-content: center;
    min-height     : 48px;
    min-width      : 48px;
    outline        : none;
    position       : absolute;
    right          : 0;
    top            : 20%;
    z-index        : 150;
}

button.glassjar__more-tab:before,
button.glassjar__more-tab:after {
    content : ' ';
    height  : 2rem;
    position: absolute;
    right   : 0;
    width   : 1rem;
    z-index : -1;
}

button.glassjar__more-tab:before {
    border-top-right-radius: .5rem;
    bottom                 : -2rem;
    box-shadow             : 0 -1rem 0 0 var(--color-interaction);
}

button.glassjar__more-tab:after {
    border-bottom-right-radius: .5rem;
    box-shadow                : 0 1rem 0 0 var(--color-interaction);
    top                       : -2rem;
}

.glassjar__more-tab__icon {
  align-items     : center;
  background: var(--color-bright);
  border-radius   : 5rem;
  color           : var(--color-interaction);
  display         : flex;
  font-size       : 1.0rem;
  height          : 1.3rem;
  justify-content : center;
  max-height      : 1.3rem;
  max-width       : 1.3rem;
  min-height      : 1.3rem;
  min-width       : 1.3rem;
  padding         : .3rem;
  position        : absolute;
  transform       : rotate(-45deg);
  transition      : .5s ease;
  width           : 1.3rem;
}

.glassjar__more-tab__icon.open {
  transform: rotate(0deg);
}

.glassjar__landing__img.glassjar__landing__img--jars {
  border       : 1px solid #040406;
  border-radius: 1rem;
  box-sizing   : border-box;
  max-width    : 100%;
  overflow     : hidden;
}

.glassjar__welcome-content a {
  color: var(--color-bright);
}

.glassjar__welcome-content p {
  color: var(--color-bright);
  font-weight: 300;
}