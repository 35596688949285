      /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

:root {
  font-family: 'Work Sans', sans-serif;

        /* --primary: #7B9F90ff; */

  --color-border      : #e3e3e3;
  --color-bright      : #ffffff;
  --color-inverse     : #f0f5f3;
  --color-secondary   : #8f8f8f;
  --color-brand       : #202230;
  --color-interaction : #7e57e8;
  --color-tertiary    : #b5b5b5;
  --color-primary     : #202230;
  --color-accent      : #414450;
  --color-form        : #8d8d8d;
  --color-danger      : #ff0000;
  --gradient-brand    : linear-gradient(160deg, #dff3e5, #6e9c88, #6e9c88, #3a6454);
  --gradient-secondary: linear-gradient(
    160deg,
    #bf8975ff,
    #b6806cff,
    #b6806cff,
    #7a4a3fff
  );
--shadow-main : 0px 1px 4px 0px rgba(17, 17, 26, 0.025),
                0px 2px 8px 0px rgba(17, 17, 26, 0.025); 

--text-stroke: -1px -1px 0 var(--color-bright),
                1px -1px 0 var(--color-bright),
                -1px 1px 0 var(--color-bright),
                 1px 1px 0 var(--color-bright),
               -2px -2px 0 var(--color-bright),
                2px -2px 0 var(--color-bright),
                -2px 2px 0 var(--color-bright),
                 2px 2px 0 var(--color-bright);
}

.glassjar__panels {
  flex  : 2;
  height: 100%;
  position: relative;
}

.glassjar__main {
  bottom        : 0;
  box-sizing    : border-box;
  display       : flex;
  flex-direction: column;
  height        : 100vh;
  left          : 0;
  margin        : auto;
  margin        : 0;
  max-height    : 100vh;
  max-width     : 100%;
  min-height    : 100vh;
  min-width     : 320px;
  overflow      : hidden;
  padding       : 0;

  position: fixed;
  right   : 0;
  top     : 0;
  width         : 100%;
}

.glassjar__text-stroke {
  text-shadow: -1px -1px 0 var(--stroke-color, #ffffff),  
  1px -1px 0  var(--stroke-color, #ffffff),
  -1px 1px 0  var(--stroke-color, #ffffff),
  1px 1px 0  var(--stroke-color, #ffffff),
  -2px -2px 0  var(--stroke-color, #ffffff),  
  2px -2px 0  var(--stroke-color, #ffffff),
  -2px  2px 0  var(--stroke-color, #ffffff),
  2px  2px 0  var(--stroke-color, #ffffff),
  -3px -3px 0  var(--stroke-color, #ffffff),  
  3px -3px 0  var(--stroke-color, #ffffff),
  -3px  3px 0  var(--stroke-color, #ffffff),
  3px  3px 0  var(--stroke-color, #ffffff);;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

.glassjar__root {
  /* background    : #fafcfa; */
  box-sizing    : border-box;
  display       : flex;
  flex-direction: column;
  height        : 100%;
  max-height    : calc(var(--vh, 1vh) * 100);
  overflow      : hidden;
  padding       : 0rem;
  position      : fixed;
  top           : 0;
  width         : 100vw;
/* 
  background     : url('./../media/images/backing.jpg');
  background-size: cover; */
}

.glassjar__fill-back {
  background: var(--box-color, var(--color-bright));
  border-radius: 2px;
  padding: 0 3px;
}

h2 {
  font-size    : 1.6rem;
  font-weight  : 300;
  margin-top: 0;
}

h3 {
  font-weight: 500;
}

.glassjar__flex {
  box-sizing: border-box;
  display   : flex;
  gap       : 1rem;
  max-width : 100%;
  width     : 100%;
}

.glassjar__flex-wrap {
  display       : flex;
  flex-direction: column;
  flex-grow     : 0;
  overflow      : hidden;
}

.glassjar__flex.glassjar__flex--even > * {
  flex : 2;
  width: 100%;
}

.glassjar__flex.glassjar__flex--column {
  flex-direction: column;
}

.glassjar__flex.glassjar__flex--tight {
  gap: .5rem;
}

.glassjar__flex.glassjar__flex--justify-center {
  justify-content: center;
}

.glassjar__flex.glassjar__flex--justify-around {
  justify-content: space-around;
}

.glassjar__flex.glassjar__flex--justify-between {
  justify-content: space-between;
}

.glassjar__flex.glassjar__flex--align-center {
  align-items: center;
}

.glassjar__flex.glassjar__flex--align-baseline {
  align-items: baseline;
}

.glassjar__flex.glassjar__flex--even > * {
  flex : 2;
  width: 100%;
}

.glassjar__swiper {
  background   : var(--color-bright);
  border-radius: 1rem;
  box-sizing   : border-box;
  flex         : 2;
  height       : calc(40vh - 1rem);
  max-width    : 100vw;
  width        : calc(100% - 2rem);
}

.glassjar__text-link {
  background   : none;
  border       : none;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  color        : var(--color-interaction);
  cursor       : pointer;
  font-weight  : inherit;
  margin       : 0;
  outline      : none;
  padding      : 0;
  transition   : .5s ease;
}

.glassjar__text-link:focus {
  border-bottom: 1px solid  var(--color-interaction);
}

.glassjar__margin-gap > * {
  margin-bottom: .5rem;
}

.glassjar__margin-gap > *:last-child {
  margin-bottom: 0;
}

.glassjar__padding {
  padding: 1rem;
}

.glassjar__padding.glassjar__padding--sides {
  padding: 0 1rem;
}

/* Auto Height */

.glassjar__auto-height {
  display           : grid;
  grid-template-rows: 0fr;
  transition        : 0.5s;
}

.glassjar__auto-height > div {
  overflow  : hidden;
  transition: 0.5s;
}

.glassjar__auto-height.open > div {
  margin-top: .5rem;
}

.glassjar__auto-height.glassjar__auto-height--top > div {
  margin-top: 0;
}

.glassjar__auto-height.open {
  grid-template-rows: 1fr;
}

.glassjar__margin-gap > .glassjar__auto-height {
  margin-bottom: 0;
}

.glassjar__margin-gap > .glassjar__auto-height.open {
  margin-bottom: .5rem;
}

.glassjar__no-wrap {
  white-space: nowrap;
}

.glassjar__no-margin {
  margin: 0;
}

.glassjar__list {
  padding: 0 1rem 150px 1rem;
}

.glassjar__mono-spaced {
  font-variant-numeric: tabular-nums;
}

/* Temp Desktop Display */

.glassjar__desktop-message {
  display: none;
}

@media screen and (min-width: 1025px) {

  .glassjar__main {
    position: absolute;
  }
  div#root {
      align-items    : center;
      display        : flex;
      flex-direction : column;
      gap            : 1rem;
      height         : 100vh;
      justify-content: center;
      width          : 100vw;
  }

  .glassjar__desktop-message {
    display    : block;
    font-weight: 300;
    max-width  : 350px;
    text-align : center;

  }
  
  .glassjar__root {
      aspect-ratio: .46;
      border      : 1px solid var(--color-inverse);
      box-shadow  : var(--shadow-main);
      height      : 100vh;
      max-height  : 90vh;
      position    : relative;
  }
}
