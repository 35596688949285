                /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__nav {
    /* overflow     : hidden; */
  position: relative;
}

.glassjar__nav.glassjar__nav--primary {
  border-radius: 0;
  border-top   : 1px solid rgba(0, 0, 0, 0.15);
  bottom       : -4px;
  position     : absolute;
  width        : 100%;
  z-index      : 9000;
}

.glassjar__nav__icon-holder {
  background   : rgb(252, 252, 252);
  border       : 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  bottom       : 0;
  box-shadow   : 0 0px 2px rgba(0,0,0,0.05), 0 0px 4px rgba(0,0,0,0.05);
  display      : flex;
  height       : 100%;
  overflow     : hidden;
  padding-top  : .2rem;
  position     : relative;
  width        : 100%;
  z-index      : 1;
}

.glassjar__nav.glassjar__nav--primary .glassjar__nav__icon-holder {
  border        : none;
  border-radius : 0;
  padding-bottom: 1rem;

}

.glassjar__nav__button {
  align-items    : center;
  background     : none;
  border         : none;
  color          : var(--color-secondary);
  display        : flex;
  flex           : 2;
  flex-direction : column-reverse;
  font-size      : .9rem;
  font-weight    : 500;
  gap            : 0.25rem;
  justify-content: center;
  transition     : .4s ease;
  width          : 100%;
}

.glassjar__nav__button.active {
  color: var(--color-dark);
}

.glassjar__nav.glassjar__nav--primary .glassjar__nav__button {
  transform: translateY(25%);
}

.glassjar__nav.glassjar__nav--primary .glassjar__nav__button.active {
  transform: translateY(10%);
}

.glassjar__nav__button i {
  font-size: 1.2rem;
}

.glassjar__nav__underline {
  align-items    : center;
  display        : flex;
  height         : .2rem;
  justify-content: center;
  pointer-events : none;
  position       : absolute;
  top            : -.05rem;
  transition     : left 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
                  width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
                  color .5s,
                  opacity 0.5s;
  z-index: 1;
}

.glassjar__nav__underline > div {
  background   : var(--color-bright);
  border       : 1px solid rgba(255, 255, 255);
  border-radius: 4rem;
  height       : 150%;
  width        : 50%;
}

.glassjar__nav__backing-shape {
  align-items    : center;
  display        : flex;
  height         : 100%;
  justify-content: center;
  pointer-events : none;
  position       : absolute;
  top            : 0;
  transition     : left 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
                  width 0.5s cubic-bezier(0.645, 0.045, 0.355, 1),
                  color 0.5s ease,
                  opacity 0.5s;
}

.glassjar__nav.glassjar__nav--primary.inactive .glassjar__nav__underline {
  opacity: 0;
}

.glassjar__nav.glassjar__nav--primary.inactive .glassjar__nav__backing-shape {
  opacity: 0;
}

.glassjar__nav__backing-shape > div {
  background: linear-gradient(90deg, transparent 0%, #ffffff 20%, #ffffff 80%, transparent 100%);
  height    : 100%;
  left      : -3rem;
  opacity   : 1;
  position  : absolute;
  top       : 0;
  width     : calc(100% + 6rem);
}

.glassjar__nav__group .glassjar__nav__body {
  max-height: 100%;
  overflow  : auto;
}

.glassjar__nav__group {
  display       : flex;
  flex-direction: column;
  max-height    : 100%;
  min-height    : 100%;
  overflow      : hidden;
  position      : relative;
}

.glassjar__nav__group .glassjar__nav {
  box-sizing : border-box;
  flex-shrink: 0;
  margin     : 1rem   1rem 5px 1rem;
}

.glassjar__nav__body {
  display       : flex;
  flex-direction: column;
  flex-grow     : 1;
}

.glassjar__nav__pill {
  align-items    : center;
  border-radius  : 1rem;
  box-sizing     : border-box;
  display        : flex;
  font-size      : 0.8rem;
  font-weight    : 500;
  justify-content: center;
  left           : 74%;
  margin         : 0;
  margin-top     : -2px;
  padding        : 0 0.5rem;
  position       : absolute;
  top            : -3px;
}

.glassjar__nav__button div {
  align-items: center;
  position   : relative;
}

  /* Header */

.glassjar__top-nav {
  align-items    : center;
  background     : var(--color-bright);
  border-bottom  : 1px solid rgba(0, 0, 0, 0.15);
  box-shadow   : 0 0px 3px rgba(0,0,0,0.05), 0 0px 4px rgba(0,0,0,0.05);
  box-sizing     : border-box;
  display        : flex;
  justify-content: space-between;
  padding        : .5rem;
  position       : relative;
  width          : 100%;
  z-index        : 9000;
}

.glassjar__top-nav > * {
  flex : 2;
  width: 100%;
}

.glassjar__top-nav > div:last-of-type {
  align-items: flex-end;
  text-align : right;
}

button.glassjar__button.glassjar__button--span {
  aspect-ratio: unset;
  white-space : nowrap;
}

button.glassjar__nav__button > span {
  opacity  : 0;
  transform: translateY(50%);
    /* height: 0; */
  transition: .25s ease;
}

button.glassjar__nav__button.active > span {
  height   : 100%;
  opacity  : 1;
  transform: scale(1)
}

.glassjar__nav__button img {
  border-radius: 100px;
  max-height   : 28px;
  max-width    : 28px;
}

.glassjar__top-nav img {
  max-height: 2rem;
  max-width : 100%;
}