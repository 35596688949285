    /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__graph-holder {
  display       : flex;
  flex          : 2;
  flex-direction: column;
  min-height    : 100%;
  position      : relative;
  --color-bright: #f4f4f4;
  --text-stroke : -1px -1px 0 var(--color-bright),
                1px -1px 0 var(--color-bright),
                -1px 1px 0 var(--color-bright),
                1px 1px 0 var(--color-bright),
                -2px -2px 0 var(--color-bright),
                2px -2px 0 var(--color-bright),
                -2px 2px 0 var(--color-bright),
                2px 2px 0 var(--color-bright),
                -3px -3px 0 var(--color-bright),
                 3px -3px 0 var(--color-bright),
                -3px  3px 0 var(--color-bright),
                 3px  3px 0 var(--color-bright);;
}

.glassjar__graph-holder__sub {
  box-sizing: border-box;
  flex      : 2 1;
  position  : relative;
}

.glassjar__panel-group.glassjar__panel-group--graph {
  box-sizing    : border-box;
  display       : flex;
  flex-direction: column;
  max-height    : 100%;
  position: relative;
}

.glassjar__graph-holder__sub-sub {
  bottom    : 0;
  box-sizing: border-box;
  height    : 100%;
  left      : 0;
  position  : absolute;
  right     : 0;
  top       : 0;
  width     : 100%;
}

.glassjar__graph-holder__sub-sub > div {
  height  : 100%;
  position: relative;
  width   : 100%;
}

.glassjar__graph-range {
  color         : #8f8f8f;
  font-size     : .8rem;
  font-weight   : 300;
  pointer-events: none;
  position      : absolute;
  text-align    : center;
  /* text-shadow: var(--text-stroke); */
  width         : 100%;
}

.glassjar__graph-range.glassjar__graph-range-max {
  top: 0rem;
}

.glassjar__graph-range.glassjar__graph-range-min {
  bottom: 0rem;
}

.glassjar__custom-tooltip {
  background   : var(--color-bright);
  border       : 2px solid #ccc;
  border-radius: .5rem;
  box-sizing   : border-box;
  padding      : .25rem .5rem;
  text-align   : center;
}

.glassjar__custom-tooltip tr td {
  text-align: left;
}

.glassjar__custom-tooltip tr td:last-of-type {
  text-align: right;
}

.glassjar__custom-tooltip h3 {
margin: 0;
}

.glassjar__custom-tooltip h4 {
  font-weight: 300;
  margin     : 0;
}

.glassjar__graph-holder__range-change {
  position: absolute;
  right   : 1rem;
  top     : 0;
}

.glassjar__graph-holder__range-change .glassjar__button.glassjar__button--small {
  aspect-ratio: unset;
  height      : 2rem;
  padding     : 0.5rem;
  position    : absolute;
  right       : 0rem;
  top         : 0;
  white-space : nowrap;
  width       : unset;
}

button.glassjar__button.glassjar__button--small {
  background: #fafcfa;
  border: 1px solid rgba(0, 0, 0, 0.05);
  color: var(--color-secondary);
  font-size: 0.9rem;
  font-weight: 500;
}

.glassjar__svg-graph h2 {
  bottom     : 0;
  font-size  : 1rem;
  font-weight: 500;
  left       : 0rem;
  margin     : 0;
  padding    : 0 0.5rem;
  position   : absolute;
  text-align : left;
  
  /* text-shadow: var(--text-stroke); */

}

.glassjar__svg-graph h2 + h2 {
  left : unset;
  right: 0;
}


.glassjar__svg-graph .glassjar__svg-graph__data {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: .75rem;
  position: absolute;
  top: 0;
  transition: .5s ease;
  z-index: 1;
}

.glassjar__svg-graph .glassjar__svg-graph__data.glassjar__svg-graph__data--end {
  align-items: flex-end;
  left: unset;
  right: .75rem;
}

.glassjar__svg-graph h5 {
  display: inline-block;  
  margin: 0;
  text-align: center;
  font-weight: 300;
}

.glassjar__svg-graph h4 {
  font-weight: 500;
  margin: 0;
}


.glassjar__svg-graph__month-box {
  /* background: linear-gradient(0deg, transparent, white, white, white, white, white, transparent); */
  box-sizing: border-box;
  opacity: 1;
  padding: 0 2px;
  pointer-events: none;
  transition: .5s ease;
  height: calc(100% - 3rem);
  top: 1.5rem;
}


.glassjar__svg-graph svg {
  position: relative;
}

.glassjar__svg-graph__month-box > div {
  background   : #f4f4f4;
  border-radius: 1.5rem;
  height       : 100%;
  position     : absolute;
  top          : 0;
  width        : 100vw;
  box-shadow   : -20px 0 10px -20px rgba(0,0,0,0.25) inset, 20px 0 10px -20px rgba(0,0,0,0.25) inset;
  /* border       : 1px solid var(--color-border); */
}

.glassjar__svg-graph__month-box > div:first-of-type {
  right: 100%;
}

.glassjar__svg-graph__month-box > div:last-of-type {
  left: 100%;
}

.glassjar__svg-graph__day-box {
  background: #b0b0b0;
  background: linear-gradient(5deg, transparent 0%, #b0b0b0 20%, #b0b0b0 80%, transparent 100%);
  height    : 100%;
  position  : absolute;
  top       : 0;
  transition: .5s ease;
  width     : 2px;
}

.glassjar__svg-graph__graph-holder {
  margin-top: 2rem;
  position: relative;
  box-sizing: border-box;
  height: calc(100% - 4rem);
}