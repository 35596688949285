        /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__list-item {
  align-items    : center;
  box-sizing     : border-box;
  display        : flex;
  gap            : .5rem;
  justify-content: space-between;
  padding        : .5rem;
  position       : relative;
  z-index        : 1;
}

.glassjar__list-item__transaction {
  border-radius  : 1rem;
  overflow       : hidden;
  /* border: 1px solid var(--color-border); */

}

.glassjar__list-item__backing {
  height       : 100%;
  left         : 0;
  opacity      : 0.1;
  position     : absolute;
  top          : 0;
  width        : 100%;
  z-index      : -1;
}

.glassjar__list-item__icon {
  align-items    : center;
  aspect-ratio   : 1;
  display        : flex;
  justify-content: center;
  padding        : 0.5rem;
  position       : relative;
  text-align     : center;
  z-index        : 1;
}

.glassjar__list-icon {
  color    : white;
  font-size: 1.5rem;
}

.glassjar__list-icon__backing {
  border-radius: 1.1rem;
  height       : 100%;
  left         : 0;
  position     : absolute;
  top          : 0;
  width        : 100%;
  z-index      : -2;
}

.glassjar__recurring-icon {
  align-items    : center;
  aspect-ratio   : 1;
  background     : white;
  border-radius  : 10rem;
  bottom         : -.8rem;
  box-sizing     : border-box;
  display        : flex;
  font-size      : .8rem;
  height         : 1.6rem;
  justify-content: center;
  padding        : 0.4rem;
  position       : absolute;
  right          : -.8rem;
  text-align     : center;
  width          : 1.6rem;
}

.glassjar__list-item h4 {
  font-weight: 500;
  margin     : 0;
}

.glassjar__list-item h5 {
  font-weight: 300;
  margin     : 0;
}

.glassjar__list-item__body {
  flex : 2;
  width: 100%;
}

.glassjar__list-item-row {
  display        : flex;
  justify-content: space-between;
}

.glassjar__list-item.glassjar__list-item--account .glassjar__svg-graph {
  border-radius : 0 0 1.2rem 1.2rem;
  height        : 100%;
  left          : 0.25rem;
  max-height    : calc(100% - 0.5rem);
  max-width     : calc(100% - 0.5rem);
  overflow      : hidden;
  pointer-events: none;
  position      : absolute;
  top           : 0.25rem;
  width         : 100%;
}

.glassjar__list-item.glassjar__list-item--account {
  flex-direction: column;
  padding: 0;
  aspect-ratio: 1.586;
  gap: 0;
  border-radius  : 1rem;
  overflow       : hidden;
  border: 1px solid var(--color-border);
}

.glassjar__list-item.glassjar__list-item--account .glassjar__list-item__header {
  align-items    : center;
  box-sizing     : border-box;
  color          : var(--color-bright);
  display        : flex;
  gap            : .5rem;
  padding        : .5rem 1rem;
  width          : 100%;
}

.glassjar__list-item.glassjar__list-item--account .glassjar__list-item__body {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 0.5rem;
  box-sizing: border-box;
}

.glassjar__list-item.glassjar__list-item--account .glassjar__list-item__icon {
  padding: 0;
}

.glassjar__list-item.glassjar__list-item--account .glassjar__list-item__header .glassjar__list-item__details {
  display: flex;
  gap: 1rem;
  white-space: nowrap;
}

.glassjar__list-item.glassjar__list-item--account .glassjar__list-item__footer {
  position       : absolute;
  bottom         : 0;
  left           : 0;
  width          : 100%;
  box-sizing     : border-box;
  padding        : .5rem;
  display        : flex;
  align-items    : baseline;
  justify-content: center;
}

.glassjar__list-item.glassjar__list-item--account .glassjar__list-item__header--mid {
  flex: 2;
}

.glassjar__list-item.glassjar__list-item--account .glassjar__list-item__header h3 {
  margin: 0;
  color: black;
  background: var(--color-bright);
  padding: 0 .5rem;
  border-radius: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.glassjar__list-item__headline {
  display: flex;
  align-items: baseline;
  gap: 0.3rem;
}

.glassjar__account-list {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.glassjar__list-item.glassjar__list-item--account.glassjar__list-item--message {
  justify-content: flex-start;
  aspect-ratio: unset;
  align-items: flex-start;
}

.glassjar__list-item__message {
  box-sizing: border-box;
  width: 100%;
}

.glassjar__list-item__message + .glassjar__list-item__message {
  border-top: 2px solid var(--color-bright);
}

/* .glassjar__list-item.glassjar__list-item--account.glassjar__list-item--message,
.glassjar__list-item.glassjar__list-item--message .glassjar__list-item__backing {
  border-radius: 0.5rem;
} */