        /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__loader {
  align-items    : center;
  background     : white;
  display        : flex;
  height         : 100%;
  justify-content: center;
  max-height     : 100vh;
  max-width      : 100vw;
  opacity        : 0;
  position       : fixed;
  transition     : 1s ease;
  width          : 100%;
  z-index        : -1;
  pointer-events: none;
}

.glassjar__loader.visible {
  z-index: 9999;
  opacity: 1;
}

.glassjar__loader h1 {
  display: none;
  color    : var(--color-brand);
  font-size: 3rem;
}

.glassjar__loader.visible h1 {
  display: block;
}