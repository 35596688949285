/* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__lazy-list__header {
  align-items: baseline;
  background : white;
  margin     : 0;
  margin     : 0.5rem 0;
  padding    : .2rem 0;
  position   : sticky;
  text-align : left;
  top        : -1px;
  width      : 100%;
  z-index    : 2;
}

.glassjar__calendar__container .glassjar__lazy-list__header {
  padding: .2rem 0 .2rem 0;
}

.glassjar__search-sort {
  align-items    : center;
  box-sizing     : border-box;
  display        : flex;
  gap            : 1rem;
  justify-content: space-between;
}

.glassjar__search-sort__field {
  flex    : 2;
  position: relative;
  width   : 100%;
}

.glassjar__search-sort__field-clear {
  color    : var(--color-tertiary);
  position : absolute;
  right    : 1rem;
  top      : 50%;
  transform: translateY(-50%);
}

.glassjar__menu.glassjar__sort-menu .glassjar__menu__body {
  display       : flex;
  flex-direction: column;
  gap           : .5rem;
  width         : 50vw;
}

.glassjar__menu.glassjar__sort-menu .glassjar__menu__body p {
  margin: 0;
}

.glassjar__panel-group.glassjar__panel-group--transactions {
  padding: 0;
}

.glassjar__transaction-list {
  display       : flex;
  flex-direction: column;
  max-height    : 100%;
  min-height    : 100%;
  overflow      : hidden;
  gap           : 1rem;
}

.glassjar__transaction-view {
  max-height    : 100%;
  overflow      : auto;
  padding-bottom:  150px;
}

.glassjar__sort-menu__filter {
  display       : flex;
  flex-direction: column;
  gap           : 1rem;
  padding-top   : 1rem;
}