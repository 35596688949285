  /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__calendar-group {
  box-sizing    : border-box;
  color         : var(--color-primary);
  display       : flex;
  flex-direction: column;
  height        : 100%;
  max-height    : 100%;
  overflow      : hidden;
  position      : relative;
}

.glassjar__calendar__calendar {
  box-sizing: border-box;
  margin    : 0 0 .5rem 0;
  min-height: 90px;
  padding   : 0.5rem 1rem 1.3rem 1rem;
  position  : relative;

  /* display: none; */

}

.glassjar__calendar-toggle {
  bottom         : -0.5rem;
  display        : flex;
  justify-content: center;
  left           : 1rem;
  position       : absolute;
}

.glassjar__calendar__calendar .glassjar__calendar__week .glassjar__calendar__seven-row {
  margin-top: 0;
}

.glassjar__calendar__seven-row {
  display        : flex;
  justify-content: space-around;
}

.glassjar__calendar__seven-row > * {
  flex       : 2;
  font-size  : .8rem;
  font-weight: 300;
  width      : 100%;
}

.glassjar__calendar__day {
  align-items    : center;
  aspect-ratio   : 1;
  border         : 1px solid transparent;
  border-radius  : 5rem;
  box-sizing     : border-box;
  display        : flex;
  font-weight    : 300;
  justify-content: center;
  padding        : 0;
  position       : relative;
  text-align     : center;
  transition     : .5s ease;
}

.glassjar__calendar__day-marker {
  background   : rgb(170, 170, 170);
  border-radius: 1rem;
  bottom       : 0.3rem;
  height       : 3px;
  position     : absolute;
  width        : .8rem;
}

.glassjar__calendar__day h1 {
    font-size  : 1rem;
    font-weight: 300;
    margin     : 0;
}

.glassjar__calendar__day.active h1 {
  font-size  : 1.2rem;
  font-weight: 500;
}

.glassjar__calendar__day.today h1 {
  font-weight: 500;
}

.glassjar__calendar__day.other-month {
  opacity: .25;
}

.glassjar__calendar__day.active {
  color     : var(--color-bright) ;
  border    : 1px solid var(--color-border);
  background: var(--color-tertiary);
  opacity   : 1;
}

.glassjar__calendar__seven-row.glassjar__calendar__seven-row--header {
    padding   : .2rem 0;
    text-align: center;
}

.glassjar__calendar__navigation {
    align-items    : center;
    display        : flex;
    justify-content: space-around;
}

.glassjar__calendar__navigation h2 {
  flex      : 2;
  text-align: center;
}

.glassjar__calendar__navigation button {
  background: none;
  border    : none;
  color     : var(--color-interaction);
  font-size : 1.4rem;
  outline   : none;
  padding   : 1rem;
}

.glassjar__calendar-view-button {
  font-size : 1.4rem;
  opacity   : .5;
  padding   : .5rem;
  transition: .5s;
}

.glassjar__calendar-view-button.selected {
  opacity: 1;
}

.glassjar__schedule__view-control {
  display        : flex;
  justify-content: flex-end;
  position       : absolute;
  right          : 0;
  top            : 0;
  z-index        : 5;
}

.glassjar__schedule {
  max-height   : 100%;
  overflow     : auto;
  padding      : 0 1rem;
  position     : relative;
}

.glassjar__calendar__container {
  box-sizing    : border-box;
  display       : flex;
  flex-direction: column;
  overflow      : hidden;
}

.glassjar__calendar__day-panel {
  box-sizing: border-box;
  flex      : 1;
  max-height: 100%;
  overflow  : auto;
  -webkit-overflow-scrolling: auto;
}

.glassjar__calendar__day-panel--graph {
  box-sizing: border-box;
  height: 20vh;
}

.glassjar__calendar__month {
  margin: 0;
}

button.glassjar__button.glassjar__button__calendar-toggle i {
  transition: .25s ease;
}

button.glassjar__button.glassjar__button__calendar-toggle i.open {
  transform: rotateX(180deg);
}