              /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__color-picker {
  display              : grid;
  grid-gap             : .5rem;
  grid-template-columns: repeat(4, 1fr);
}

.glassjar__color-picker__color {
  border    : 1px solid var(--color-border);
  height    : 2rem;
  padding   : 0;
  transition: .5s ease;
  width     : 100%;
}

.glassjar__color-picker__color.glassjar__color-picker__color--selected {
  border: 1px solid var(--color-brand)
}