            /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__panel-group {
  box-sizing    : border-box;
  flex          : 2 1;
  flex-grow     : 1;
  height        : 100%;
  min-height    : 100%;
  min-width     : 100%;
  opacity       : 0;
  overflow-y    : auto;
  padding       : 1rem;
  pointer-events: none;
  position      : absolute;
  transition    : opacity 0.6s, transform 0.6s;
  width         : 100%;
  z-index       : -1;
}

.glassjar__panel-group.glassjar__panel-group--calendar {
  padding: 0;
}

.glassjar__account-list__header h2 {
  margin: 0;
}

.glassjar__graph-holder h2 {
  margin-top: 0;
}

.glassjar__panel-group.visible {
  opacity       : 1;
  pointer-events: auto;
  visibility    : visible;
  z-index       : 1;
}

.glassjar__list > h3 {
  margin-top: 1rem;
  text-align: center;
  width     : 100%;
}

.glassjar__grid.glassjar__grid--projected-info {
  display              : grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom        : 1rem;
  width                : 100%;
  background           : linear-gradient(320deg, #dddddd, #f9f9f9);
  border-radius        : 1.4rem;
}

.glassjar__grid.glassjar__grid--projected-info h4:nth-child(even),
.glassjar__grid.glassjar__grid--projected-info h5:nth-child(even) {
  text-align: right;
}

.glassjar__grid.glassjar__grid--projected-info > * {
  padding: .25rem 1rem;
}

.glassjar__grid.glassjar__grid--projected-info h4 {
  font-size     : 1.2rem;
  font-weight   : 500;
  margin        : 0;
  padding-top   : .5rem;
  padding-bottom: .5rem;
}

.glassjar__grid.glassjar__grid--projected-info h5 {
  background: #ffffff75;
}

.glassjar__grid.glassjar__grid--projected-info h5 {
  font-size   : 1rem;
  font-weight : 300;
  margin      : 0;
  padding-left: 1.5rem;
  white-space : nowrap;
}

.glassjar__panel-group {
  padding-bottom: 150px;
}

.glassjar__svg-graph {
  height        : 100%;
  overflow      : hidden;
  position      : absolute;
  width         : 100%;
}

.glassjar__account-panel__graph {
  flex         : 2 1;
  height       : 100%;
  aspect-ratio : 2;
  position     : relative;
  overflow     : hidden;
  flex-shrink  : 0;
  margin       : 0 1rem;
  box-sizing   : border-box;
  background   : var(--color-bright);
  border-radius: .5rem;
  border       : 1px solid var(--color-border);
}

.glassjar__panel.glassjar__panel--account {
  display       : flex;
  flex-direction: column;
  gap           : .5rem;
  padding-bottom: 1rem;
}

.glassjar__panel.glassjar__panel--account h3 {
  font-size  : 1.2rem;
  font-weight: 500;
  padding    : .5rem 0;
  margin     : 0;
}

.glassjar__panel.glassjar__panel--account .glassjar__transaction-view {
  padding-bottom: 0;
}

.glassjar__panel.glassjar__panel--account .glassjar__form {
  gap: 0;
}

.glassjar__list-item__message + .glassjar__list-item__message {
  border-top: 1px solid var(--color-border);
}

.glassjar__modal__subgroup .glassjar__list-item__message {
  padding: .5rem;
  box-sizing: border-box;
}

.glassjar__panel.glassjar__panel--account .glassjar__form > .glassjar__form__input-group {
  margin-bottom: 0.5rem;
}

button.glassjar__text-button.glassjar__text-button--smaller {
  font-size: 0.8rem;
  font-weight: 500;
}
