  /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__menu__body {
  background    : var(--color-bright);
  border        : 1px solid var(--color-bright);
  border-radius : 1rem;
  box-shadow    : 0 10px 10px rgb(0 0 0 / 20%);
  max-width     : 340px;
  opacity       : 0;
  padding       : 1rem;
  pointer-events: none;
  position      : absolute;
  right         : 0;
  top           : 100%;
  transform     : translateY(-2rem);
  transition    : .1s ease-out;
  z-index       : 1000;
}

.glassjar__menu {
  position: relative;
}

.glassjar__menu__body.glassjar__menu__body--open {
  opacity       : 1;
  pointer-events: auto;
  transform     : translateY(0);
}

.glassjar__menu__button {
  align-items    : center;
  cursor         : pointer;
  display        : flex;
  height         : 2.6rem;
  justify-content: center;
  text-align     : center;
  width          : 2.6rem;
}

.glassjar__menu__button i {
  font-size: 1.6rem;
}