  /* 
  ____ _                   _            
 / ___| | __ _ ___ ___    | | __ _ _ __ 
| |  _| |/ _` / __/ __|_  | |/ _` | '__|
| |_| | | (_| \__ \__ \ |_| | (_| | |   
 \____|_|\__,_|___/___/\___/ \__,_|_|    

© 2024 Greg Sullivan

*/

.glassjar__modal {
  align-items    : center;
  bottom         : 0;
  display        : flex;
  justify-content: center;
  justify-content: center;
  left           : 0;
  max-height     : 100vh;
  max-width      : 100vw;
  min-height     : 100vh;
  min-width      : 100vw;
  pointer-events : none;
  position       : fixed;
  right          : 0;
  top            : 0;
  z-index        : 9997;
}

.glassjar__modal.open {
  pointer-events: auto;
}

.glassjar__modal__content h2 {
  margin-top: 0;
}

.glassjar__modal__backing {
  backdrop-filter: blur(0px) saturate(1);
  background     : rgba(255, 255, 255, 0.0);
  bottom         : 0;
  height         : 100%;
  left           : 0;
  opacity        : 0;
  position       : absolute;
  right          : 0;
  top            : 0;
  transition     : .25s ease;
  width          : 100%;
  z-index        : 9996;
}

.glassjar__modal.open .glassjar__modal__backing {
  backdrop-filter: blur(2px) saturate(0.0);
  background     : rgba(216, 216, 216, 0.8);
  opacity        : 1;
}

.glassjar__modal__body {
  background    : var(--color-bright);
  background    : #f7f7f7;
  border        : 1px solid var(--color-bright);
  border-radius : .5rem;
  box-shadow    : 0 10px 10px rgb(0 0 0 / 20%);
  box-shadow    : var(--shadow-main);
  box-sizing    : border-box;
  max-height    : 90vh;
  max-width     : 90vw;
  min-width     : 90vw;
  overflow-y    : auto;
  position      : relative;
  z-index       : 9999;
  transition    : .25s ease;
  opacity       : 0;
  display       : flex;
  flex-direction: column;

}

.glassjar__modal.open .glassjar__modal__body {
  opacity       : 1;
}

.glassjar__modal__body .glassjar__padding {
  max-height: 100%;
  overflow: auto;
}

.glassjar__modal__close {
    align-items    : center;
    background     : var(--color-bright);
    border-radius  : 5rem;
    box-shadow     : 0 5px 5px rgb(0 0 0 / 10%);
    cursor         : pointer;
    display        : flex;
    height         : 2rem;
    justify-content: center;
    position       : absolute;
    right          : -1rem;
    top            : -1rem;
    width          : 2rem;
    z-index        : 5;
}

.glassjar__modal__content {
  box-sizing: border-box;
  max-height: inherit;
  position  : relative;
}

.glassjar__modal__body > * {
  flex-shrink: 0;
}

.glassjar__modal__subgroup {
  padding: 0.5rem;
  background: var(--color-bright);
  border-radius: .5rem;
  border: 1px solid var(--color-border);
  overflow: hidden;
}

.glassjar__modal__subgroup.glassjar__modal__subgroup--no-padding {
  padding: 0;
}
